<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center" class="text-center">
      <v-col cols="12" sm="8" md="12">
        <p class="page_heading ">Your profile is under review</p>
        <v-btn
          class="text-none btn_text elevation-0"
          :to="{ name: 'Login' }"
          color="secondary"
        >
          <span class="btn_text font-weight-bold">{{ $t("goLogin") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Verified"
};
</script>

<style scoped></style>
